/* $display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 2.5rem,
  5: 2rem,
  6: 1.5rem
); */

.reactMarkDown blockquote {
    border-left: .25em solid #523f3f;
    color: #131313;
    margin: 1.75em 0 1.75em 1.75em;
    padding: 0 1em;
}

.reactMarkDown blockquote p{
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 16px;
    margin-left: 0px;
}

.reactMarkDown h1 {
    font-size: 1.3rem;
}

.reactMarkDown h2 {
    font-size: 1.25rem;
}

.reactMarkDown h3 {
    font-size: 1.2rem; 
}

.reactMarkDown h4 {
    font-size: 1.1rem; 
}

.reactMarkDown h5 {
    font-size: 1.0rem; 
}

.reactMarkDown h6 {
    font-size: 1.0rem; 
}

.reactMarkDown p {
    font-style: normal !important;
}

.reactMarkDownHomePage p {
    font-size: 1.15rem;
}

.reactMarkDownHomePage h1 {
    font-size: 1.3rem;
    text-align: center;
}

.reactMarkDownHomePage h2 {
    font-size: 1.25rem;
    text-align: center;
}

.reactMarkDownHomePage h3 {
    font-size: 1.2rem; 
    text-align: center;
}

.reactMarkDownHomePage h4 {
    font-size: 1.1rem; 
    text-align: center;
}

.reactMarkDownHomePage h5 {
    font-size: 1.0rem; 
    text-align: center;
}

.reactMarkDownHomePage h6 {
    font-size: 1.0rem; 
    text-align: center;
}