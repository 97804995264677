.bio-page-accordion {
  font-size: 0.9em;
  word-wrap: break-word;
}

.bio-page-accordion div {
  background-color: #E7E1DA;
  padding-top: 0;
  border: none;
}

.bio-page-accordion p {
  margin-left: 12px;
  margin-top: 4px;
  padding-top: 10px;
  font-size: 1.2em;
  font-style: italic;
}

.bio-page-accordion .accordion-item {
  margin-bottom: 20px;
}

.bio-page-accordion button {
  color: white;
  background-color: #533f3f ;
  padding: 0px 16px 0px 16px;
}

.bio-page-accordion button:not(.collapsed) {
  color: white !important;
  background-color: #533f3f ;
}

.bio-page-accordion .accordion-button::after {
  color: white !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
}

.hide-accordion-arrow .accordion-button::after {
  display: none;
}

.bio-page-accordion .fieldtitle {
  color: black;
  display: block;
  font-weight: bold;
  margin-block-end: 9px;
  margin-block-start: 33px;
  margin-bottom: 0px;
  margin-inline-end: 0px;
  margin-inline-start: 0px;
  margin-top: 20px;
}

.bio-page-accordion .fieldvalue {
  color: black;
  display: block;
  margin-block-end: 9px;
  margin-block-start: 33px;
  margin-bottom: 0px;
  margin-inline-end: 0px;
  margin-inline-start: 0px;
  margin-top: 20px;
}

.family-heading {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
  margin-inline-end: 0px;
  margin-inline-start: 0px;
  margin-top: 20px;
}

@media only screen and (min-width: 600px) {
  .bio-button-toolbar {
    position: relative;
    left: -10px;
    top: -40px;
  }
}

.bio-page-accordion a {
  text-decoration: none;
  color: inherit;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid rgba(193, 37, 37, 1);
}

.info-details {
  margin: 10px;
  background-color: #533f3f ;
  color: white;
  border-radius: 0;
}

.bio-page-accordion a[href^="http"]::after,
.bio-page-accordion a[href^="https://"]::after
{
  content: "";
  width: 11px;
  height: 11px;
  margin-left: 5px;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%20512%20512%27%3E%3C%21--%21%20Font%20Awesome%20Pro%206.1.1%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%20%28Commercial%20License%29%20Copyright%202022%20Fonticons%2C%20Inc.%20--%3E%3Cpath%20d%3D%27M384%20320c-17.67%200-32%2014.33-32%2032v96H64V160h96c17.67%200%2032-14.32%2032-32s-14.33-32-32-32L64%2096c-35.35%200-64%2028.65-64%2064V448c0%2035.34%2028.65%2064%2064%2064h288c35.35%200%2064-28.66%2064-64v-96C416%20334.3%20401.7%20320%20384%20320zM488%200H352c-12.94%200-24.62%207.797-29.56%2019.75c-4.969%2011.97-2.219%2025.72%206.938%2034.88L370.8%2096L169.4%20297.4c-12.5%2012.5-12.5%2032.75%200%2045.25C175.6%20348.9%20183.8%20352%20192%20352s16.38-3.125%2022.62-9.375L416%20141.3l41.38%2041.38c9.156%209.141%2022.88%2011.84%2034.88%206.938C504.2%20184.6%20512%20172.9%20512%20160V24C512%2010.74%20501.3%200%20488%200z%27%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}
