.faq-page-accordion {
    font-size: 0.9em;
    word-wrap: break-word;
  }
  
  .faq-page-accordion div {
    background-color: #E7E1DA;
    padding-top: 0;
    border: none;
  }
  
  .faq-page-accordion p {
    margin-left: 12px;
    margin-top: 4px;
    padding-top: 10px;
    font-size: 1.2em;
  }
  
  .faq-page-accordion .accordion-item {
    margin-bottom: 20px;
  }
  
  .faq-page-accordion button {
    color: white;
    background-color: #533f3f ;
    padding: 0px 16px 0px 16px;
  }
  
  .faq-page-accordion button:not(.collapsed) {
    color: white !important;
    background-color: #533f3f ;
  }
  
  .faq-page-accordion .accordion-button::after {
    color: white !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
  }
  
  .hide-accordion-arrow .accordion-button::after {
    display: none;
  }
  
  .faq-page-accordion .fieldtitle {
    color: black;
    display: block;
    font-weight: bold;
    margin-block-end: 9px;
    margin-block-start: 33px;
    margin-bottom: 0px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    margin-top: 20px;
  }
  
  .faq-page-accordion .fieldvalue {
    color: black;
    display: block;
    margin-block-end: 9px;
    margin-block-start: 33px;
    margin-bottom: 0px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    margin-top: 20px;
  }
  
  .family-heading {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    margin-top: 20px;
  }
  
  @media only screen and (min-width: 600px) {
    .bio-button-toolbar {
      position: relative;
      left: -10px;
      top: -40px;
    }
  }
  
  .faq-page-accordion a {
    text-decoration: none;
    color: inherit;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid rgba(193, 37, 37, 1);
  }
  
  .info-details {
    margin: 10px;
    background-color: #533f3f ;
    color: white;
    border-radius: 0;
  }
  