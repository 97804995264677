

.sub-headings {
    background-color: #E7E1DA;
}

.highlight-words {
    font-weight: bold;
}

.help-page-headings {
    background-color:#533f3f;
    color: white;
    margin-bottom: 1%;
    padding: 10px;
}