// based on https://codepen.io/NielsVoogt/pen/MbMMxv/
// ----------------  LAYOUT

.timeline-container {
	margin: 0 auto;
    padding-right: 0px;
}

.timeline-link {
	width: fit-content;
	text-decoration: none;
	color: black;
	position: relative;
	cursor: pointer;
	border-bottom: 1px solid rgba(193, 37, 37, 1);
  }
  

// ----------------  THE FONTS

$border-color: rgba(black, .3);

.timeline-item {
	padding: 3em 0em 0.1em 2em;
	position: relative;
	color: black;
	border-left: 2px dashed $border-color;	
	
	p {
		font-size: 0.9em;

	}
	
	&::before {
		content: attr(date-is);
		position: absolute;
		left: 2em;
		font-weight: bold;
		top: 0.9em;
		display: block;
		font-size: .9rem;
	}

	&::after {
		width: 14px;
		height: 14px;
		display: block;
		top: 1em;
		position: absolute;
		left: -8px;
		border-radius: 0px;
		transform: rotate(45deg);
		content: '';
		background: #533f3f ; 
	}
}