/*search box css start here*/
.search-button {
  background-color: #354D73;
  color: white;
  border-color: black;
  font-size: 20px;
}

.search-sec {
  padding: 1.5rem;
}

.search-slt-home {
  display: block;
  width: 100%;
  line-height: 1.5;
  color: #55595c;
  background-color: #E7E1DA;
  background-image: none;
  border: 1px solid black;
  height: 40px !important;
  border-radius: 0;
}

.search-slt-home:focus {
  background-color: #E7E1DA;
}

.search-slt {
  display: block;
  width: 100%;
  line-height: 1.5;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  height: 40px !important;
  border-radius: 0;
}

.search-select {
  display: block;
  width: 100%;
  line-height: 1.5;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  height: calc(3rem + 2px) !important;
  border-radius: 0;
}

.search-panel {
  background-color: #E7E1DA;
}

.search-button-row {
  background-color: #fff;
}

.search-icon {
  background-color: #fff;
  border: 1px solid #ccc;
}

.clear-filter-button {
  border-radius: 0;
  width: fit-content;
}

.filter-button {
  height: 40px;
  font-weight: bold;
  border-radius: 0;
  background-color: #533f3f ;
}

.search-bar {
  background-color: rgba(255, 255, 255, 0.8);
  mix-blend-mode: normal;
}

.wrn-btn {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  height: 40px !important;
  border-radius: 0;
}

.cross-button {
  font-size: 25px;
  color: #533f3f ;
}

.search-table{
  max-width: 78rem;
  overflow: auto;
}

.chevron-button {
  height: 40px;
  font-weight: bold;
  border-radius: 0;
  background-color: #533f3f ;
}

.hide-show-col-dropdown-menu {         
  max-height: 30rem;
  overflow-y: auto;
}

.chevron-icon {
  -webkit-text-stroke: 1px;
}

.search-slt:placeholder-shown {
  font-size: 0.875em;
}

@media (min-width: 992px) {
  .search-sec {
    position: relative;
    bottom: 0px;
  }
}

@media (max-width: 992px) {
  .search-sec {
    position: relative;
    bottom: 0px;
  }
}
