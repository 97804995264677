.brand-logo-1 {
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  padding: 5px 10px 5px 0px;
  height: 55px;
}

.brand-logo-2 {
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  color: black;
  padding: 5px 10px 5px 3px;
  height: 50px;
}

.menu-divider {
  width: 1px;
  height: 25px !important;
  color: black;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0;
}

.nav-title-box {
  padding-left: 10px;
}

.nav-bar-2 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
    url(../assets/nav-background.jpg);
}

.nav-title-box-2 {
  color: black;
  padding-left: 10px;
}

.nav-1-menu {
  font: 1.1em "Copernicus W01", Georgia, "Times New Roman", Times, serif !important;
  white-space: nowrap;
}

.nav-2-menu {
  font: bold 1.1em "Copernicus W01", Georgia, "Times New Roman", Times, serif !important;
  color: black !important;
  white-space: nowrap;
}

.nav-title-text {
  font: bold 0.8em "Copernicus W01", Georgia, "Times New Roman", Times, serif !important;
  line-height: 120%;
}

.nav-subtitle-text {
  font: bold 1em "Copernicus W01", Georgia, "Times New Roman", Times, serif !important;
  line-height: 120%;
}

.dropdown-item {
  font-size: 1em !important;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.nav-link {
  text-decoration: none;
  color: #031d44;
  margin: 0px 10px;
  display: inline-block;
  position: relative;
}

.navbar {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.navbar-shadow-none {
  box-shadow: none !important;
}

.nav-z-index {
  z-index: 1000;
}

@media only screen and (min-width: 993px) {
  .nav-link {
    padding: 20px 0px;
  }

  .nav-link::before {
    transition: 300ms;
    height: 2px;
    content: "";
    position: absolute;
    background-color: #c02325;
    width: 0%;
    bottom: 10px;
  }

  .nav-link:hover::before {
    width: 95%;
  }

  .dropdown-menu {
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.5);
    border-radius: 1px;
  }
}

@media only screen and (max-width: 992px) {
  .dropdown-menu {
    border-width: 0px;
  }

  .dropdown-divider {
    display: none;
  }

  .menu-divider {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .brand-logo-1 {
    border-width: 0px 1px 0px 0px;
    border-style: solid;
    padding: 5px 10px 5px 0px;
    height: 35px;
  }

  .brand-logo-2 {
    border-width: 0px 1px 0px 0px;
    border-style: solid;
    color: black;
    padding: 5px 10px 5px 3px;
    height: 33px;
  }

  .nav-title-text {
    font: bold 0.5em "Copernicus W01", Georgia, "Times New Roman", Times, serif !important;
    line-height: 60%;
  }

  .nav-subtitle-text {
    font: bold 0.6em "Copernicus W01", Georgia, "Times New Roman", Times, serif !important;
    line-height: 60%;
  }
}
