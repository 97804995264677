.admin-side-menu-card {
  background-color: black;
  color: white;
  border-radius: 0% !important;
}

.action-card {
  border: 0ch;
  box-shadow: none;
  background-color: #ffffff;
}

.action-card-circle {
  background-color: #ffffff;
  width: 65px;
  height: 65px;
  box-shadow: 0px 1px 8px rgb(0 0 0 / 20%), 0px 2px 8px rgb(0 0 0 / 12%), 0px 1px 8px rgb(0 0 0 / 14%);
  display: flex;
  align-items: center;
}

.entry-summary-card a {
  text-decoration: none;
  color: inherit;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid rgba(193, 37, 37, 1);
  width: fit-content;
}

.overview-icons {
  font-size: 35px;
  margin: 0 !important;
  vertical-align: middle;
}

.overview-icons:hover {
  font-size: 35px;
  margin: 0 !important;
  vertical-align: middle;
}

.summary-stats:hover {
  color: #c12424;
}
.action-card:hover {
  box-shadow: none;
  color: #c12424;
}
.action-detailed-card {
  box-shadow: 0px 1px 3px rgb(0 0 0 / 20%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 1px rgb(0 0 0 / 14%);
}

.action-detailed-card:hover {
  box-shadow: 0px 1px 8px rgb(0 0 0 / 20%), 0px 2px 8px rgb(0 0 0 / 12%), 0px 1px 8px rgb(0 0 0 / 14%);
  background-color: #f5f5f5;
}

.welcome {
  font-weight: bold;
  color: #c12424;
}

.info-message {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.click-here-link {
  color: #c12424;
  font-weight: bold;
}

.glossary-and-place-action-buttons {
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.glossary-and-place-action-buttons :hover {
  color: #c12424;
}

.glossary-and-place-add-button {
  background-color: #533f3f ;
  display: block;
  width: 100%;
}
