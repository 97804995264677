.field-array-style {
  padding: 15px 15px 15px 15px;
  background: #f5f5f5;
  border-radius: 5px;

  > label {
    color: #545454;
    font-size: 1.15rem;
    margin-bottom: 20px;
  }

  & .sub-field-array-style {
    border: 1px solid #dfdddd;
    padding: 15px 15px 20px 15px;
    margin: 0px 10px 0px 0px;
    border-radius: 5px;
    background: #e7e7e7;

    & .subTitle {
      padding: 0;
      margin: 0 0 0 6px;
      color: #707070;
      font-size: 13px;
    }
  }
}

.field-array-row {
  margin: 0px 0px 10px 0px;
}

.field-column {
  padding-left: 0px;
  padding-top: 0px;
}

.form-button {
  margin-right: 1rem;
}

.biography-form-container {
  margin-top: 3.5rem;

  & h2 {
    margin-bottom: 1.5rem;
  }
}

.navbar-custom-style {
  margin-top: 5rem;
  background: #f5f5f5;
  border-bottom: 1px solid #e7e7e7;
  padding: 15px 0px;
  box-shadow: none;
}

// React Markdown css override
.wmde-markdown,
.w-md-editor {
  background-color: #f7f7f7;
  color: black;
}

.w-md-editor-toolbar {
  background-color: #533F3F !important;
}

.w-md-editor-toolbar li > button {
  color: white !important;
}

// React Accordion css
.accordion-button-error {
  button {
    background-color: #f8d7da !important;
    color: black !important;
    border: 0px;
    box-shadow: none !important;
  }
  .collapsed {
    background-color: #f8d7da;
    color: black !important;
    border: 0px;
  }
}

.accordion-item-error {
  background-color: #fff;
  border: 1px solid #f5c6cb;
}

.accordion-item {
  h2 {
    margin: 0px;
  }
}

.date-info-button {
  background: none;
  background-color: white;
  border: none;
}

.date-info-link {
  background: none;
  background-color: #e7e7e7;
  border: none;
  color: #707070;
}

.modal-height {
  height: 50%;
}

.search-modal-dialog {
  height: 95vh !important;
  max-height: 95vh !important;
  & .modal-dialog {
    width: 85vw !important;
    max-width: 85vw !important;
  }
}

.add-stub-entry-modal-dialog {
  height: 95vh !important;
  max-height: 95vh !important;
  & .modal-dialog {
    width: 60vw !important;
    max-width: 60vw !important;
  }
}

.bio-state-description {
  text-decoration: none;
  padding: 0px;
}
