.jumbotron-image {
  background-size: cover;
  background-image: linear-gradient(
      90deg,
      rgba(255,255,255,.1), rgba(255,255,255,.1), rgba(255,255,255,.1)
    ),
    url(../assets/new-home-banner.jpg);
}

@media (min-width: 992px) {
  .jumbotron-image {
    background-position: top center;
    background-size: cover;
  }
}

@media (max-width: 992px) {
  .jumbotron-image {
    background-position: 70% 0%;
    background-size: cover;
  }
}

